.CityCard {
  width: 370px;
  height: 370px;
  min-width: 370px;
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
  color: white;
  font-size: 3.5em;
  line-height: 1.3em;
  text-transform: uppercase;
  box-shadow: 10px 50px 20px -10px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  animation: animateIn 300ms both ease-in-out;

  &:hover {
    cursor: pointer;

    .container {
      .picture {
        width: 110%;
        height: 110%;
      }
    }
  }

  .container {
    text-align: center;

    .name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 20px;
      z-index: 1;
    }

    .picture {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      transition: all 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 481px) {
    width: 300px;
    height: 300px;
    min-width: 300px;
    min-height: 300px;
  }
}
