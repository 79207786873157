@keyframes fade-in {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

.ScrollToTop {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 35px;
  animation: fade-in 0.5s ease-in-out;

  .scrollButton {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
