.Header {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  animation: animateInHeight 1s 0.5s both ease-in-out;

  .languageWrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 100%;
    z-index: 1000;
  }

  .nav {
    display: flex;
    align-items: center;
    width: 80%;
    position: relative;
    justify-content: flex-start;
    gap: 40px;

    .logo > img {
      max-height: 100px;
      object-fit: contain;
    }
  }

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 80%;
    min-height: 55%;
    position: relative;

    .content {
      color: #ffffff;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      max-width: 842px;
      font-weight: 700;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      -webkit-border-radius: 20px;
      padding: 40px 55px;

      animation: animateBackgroundOpacityEnter 1s both 1.5s ease-in-out;

      .title {
        font-size: 52px;
        text-transform: uppercase;

        animation: animateTextEnter 0.5s both 1.5s ease-in-out;
      }

      .description {
        font-family: 'Nunito', serif;
        font-size: 24px;

        animation: animateTextEnter 0.5s both 2s ease-in-out;
      }
    }

    .scrollWrapper {
      padding: 35px;
      width: 100%;

      .scrollButton {
        margin: auto;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 12px 16px;
        border: 3px solid;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      .content {
        .title {
          font-size: 2.5em;
        }

        .description {
          font-size: 1.3em;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      .content {
        .title {
          font-size: 1.5em;
        }

        .description {
          font-size: 1em;
        }
      }
    }
  }
}