.SearchBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  font-size: 18px;

  animation: enterIn 0.5s both ease-in-out;

  &:focus-within {
    .searchLabel {
      display: block;
    }
  }

  .searchLabel {
    display: none;
    position: absolute;
    left: 50px;
    top: 8px;
    background: #ffffff;
    z-index: 1;

    animation: enterInBottom 0.2s both ease-in-out;
  }

  .searchInput {
    padding: 14px 35px 14px 20px;
    border: 3px solid #ccc;
    border-top-left-radius: 37px 140px;
    border-top-right-radius: 23px 130px;
    border-bottom-left-radius: 110px 19px;
    border-bottom-right-radius: 120px 24px;
    box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
    outline: none;
    width: 100%;
    position: relative;
    color: black;

    &::placeholder {
      color: black;
      opacity: 0.7;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .clearSearchButton {
    position: absolute;
    right: 40px;
    transform: translateY(-50%);
    width: 10px;

    animation: enterIn 0.3s both ease-in-out;
  }
}