/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                       TYPOGRAPHY
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@font-face {
  font-family: 'Chaloops';
  src: url('../fonts/Chaloops.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito.ttf') format('truetype');
}

@font-face {
  font-family: 'Draft';
  src: url('../fonts/Draft.ttf') format('truetype');
}
