.CookieConsent {
  font-size: 0.8em;
  align-items: center !important;
  justify-content: center !important;
  background: #2b373b;
  opacity: 0.95;
  padding: 10px 40px;

  .cookieButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .cookieButton, .cookieDeclineButton {
      color: #4e503b;
      width: 80px;
    }
  }

  @media screen and (max-width: 449px) {
    .cookieButtonWrapper {
      width: 100%;
    }
  }
}
