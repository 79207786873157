.Button {
  .default-btn {
    padding: 10px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1em;
    text-align: center;
    svg {
      margin-right: 5px;
    }
  }
}