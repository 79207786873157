.Content {
  background: #ffffff;
  width: 100%;

  .contentWrapper {
    margin: auto;
    padding: 20px 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
  }

  .citiesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 80px;
    margin: 20px 0;
  }

  .noResults {
    font-size: 24px;
    animation: animateIn 300ms both ease-in-out;
  }

  .becomePartner {
    padding: 40px;

    .becomePartnerButton {
      border: 2px solid;
      border-right-width: 3px;
      border-bottom-width: 5px;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      font-size: 25px !important;
      padding: 15px 30px !important;
      text-transform: uppercase;
      line-height: 1.5em !important;
      transition: letter-spacing 0.2s ease-in-out, word-spacing 0.2s ease-in-out, padding 0.2s ease-in-out;

      &:hover {
        word-spacing: 0.2em;
        letter-spacing: 0.03em;
        padding: 15px 16px !important;
      }

      &:active {
        border-right-width: 2px;
        border-bottom-width: 2px;
        margin-bottom: 3px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .citiesWrapper {
      justify-content: center;
    }
  }

  @media screen and (max-width: 996px) {
    .contentWrapper {
      padding: 40px;
    }

    .citiesWrapper {
      gap: 40px;
    }
  }
}